@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

//@import "bootstrap/scss/functions";
//@import "bootstrap/scss/variables";
//@import "bootstrap/scss/mixins";
//
//@import "bootstrap/scss/maps";
//@import "bootstrap/scss/root";
//@import "bootstrap/scss/grid";
//@import "bootstrap/scss/utilities";
//@import "bootstrap/scss/containers";
//@import "bootstrap/scss/helpers";
//@import "bootstrap/scss/reboot";
@import "bootstrap/scss/bootstrap";


html {
  height: 100%;
}

body {
  height: 100%;
}
